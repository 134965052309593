import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Outlet} from "react-router-dom";
import {AccessTokenActionType, useAccessTokenDispatch} from "./contexts/AccessToken";
import {useEffect} from "react";

export default function App() {
  const accessTokenDispatch = useAccessTokenDispatch();

  useEffect(() => {
    // @ts-ignore
    accessTokenDispatch({
      type: AccessTokenActionType.SET_TOKEN,
      payload: localStorage.getItem('access_token')
    })
  }, [])

  return (
    <Outlet />
  );
}
