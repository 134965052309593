import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import router from "./routes/router";
import {RouterProvider} from "react-router-dom";
import AccessTokenProvider from "./contexts/AccessToken/AccessTokenProvider";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AccessTokenProvider>
        <RouterProvider router={router} />
      </AccessTokenProvider>
    </ThemeProvider>
  </React.StrictMode>
);
