import React from "react";
import {useAccessToken} from "../../contexts/AccessToken";
import {Navigate, Outlet, useLocation} from "react-router-dom";

export default function AuthLayout(){
  const accessToken = useAccessToken();
  const location = useLocation();

  return (
    accessToken.value ? (
      <Navigate to={location.state?.from || '/'} />
    ) : (
      <Outlet />
    )
  );
}