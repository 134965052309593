import Grid from "@mui/material/Grid";
import * as React from "react";
import {useEffect, useState} from "react";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import {useAccessToken} from "../../contexts/AccessToken";
import {
  Box,
  Button,
  Chip,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Slide, useMediaQuery, useTheme
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {TransitionProps} from "@mui/material/transitions";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {ORDERS_API} from "../../utils/API";

export interface OrderInterface{
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
  }
  volume: string;
  amount: string;
  market: string;
  tracking_number: string;
  status: string;
  type: string;
  created_at: {
    fa: string;
    en: string;
  };
  voucher?: {
    voucher_code: string;
    activation_code: string;
    batch_number: string;
  };
  card?: {
    card_number: string;
    iban: string;
    owner: string;
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrdersPage(){
  const [orders, setOrders] = useState<OrderInterface[]>([]);
  const [columns, setColumns] = useState<MUIDataTableColumnDef[]>([]);
  const [options, setOptions] = useState<MUIDataTableOptions>({})
  const [params, setParams] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const accessToken = useAccessToken();
  const [orderModal, setOrderModal] = useState<boolean>(false);
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderInterface | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setLoading(true);
    fetch(ORDERS_API + '?' + params, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken.value}`
      },
    })
      .then(response => {
        if(response.ok){
          return response.json();
        }

        return Promise.reject(response);
      })
      .then(data => {
        setOrders(data.data);
        setTotal(data.meta.total)
      })
      .catch((error) => {
        if(error instanceof Response){
          error.json().then((data: any) => {
            let errMessage = '';
            if(data?.data?.errors && data.data.errors.length > 0){
              errMessage = data.data.errors[0]
            }else{
              errMessage = data.message;
            }

          })
        }else{

        }
      })
      .finally(() => {
        setLoading(false);
      })
  }, [params])

  useEffect(() => {
    setOptions({
      selectableRows: 'none',
      filterType: 'checkbox',
      responsive: 'simple',
      count: total,
      serverSide: true,
      onTableChange: (action, tableState) => {
        let params = {};

        if(!!tableState.searchText){
          // @ts-ignore
          params['q'] = tableState.searchText;
        }
        if(!!tableState.sortOrder.name){
          // @ts-ignore
          params['sb'] = tableState.sortOrder.name;
        }
        if(!!tableState.sortOrder.direction){
          // @ts-ignore
          params['sd'] = tableState.sortOrder.direction;
        }

        for(let i =0; i < tableState.filterList.length; i++){
          if(tableState.filterList[i].length > 0){
            // @ts-ignore
            params[tableState.columns[i].name] = tableState.filterList[i];
          }
        }
        // @ts-ignore
        params['page'] = tableState.page;

        // @ts-ignore
        params['pp'] = tableState.rowsPerPage;

        let searchParams = new URLSearchParams(params).toString();

        setParams(searchParams);
      }
    })
  }, [total]);

  useEffect(() => {
      let cols: MUIDataTableColumnDef[] = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false
        }
      },
      {
        name: "tracking_number",
        label: "TNUM",
        options: {
          filter: false
        }
      },
      {
        name: "user_id",
        label: "User",
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            if(orders[dataIndex] !== undefined){
              let user = orders[dataIndex].user;
              return (
                <Chip
                  icon={<AccountCircleIcon />}
                  label={'USR' + user.id}
                  variant="outlined"
                  onClick={() => handleOpenUserInfoModal(dataIndex)}
                />
              );
            }
            return '-';
          }
        }
      },
      {
        name: "volume",
        label: "Volume",
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            if(orders[dataIndex] !== undefined){
              let volume = orders[dataIndex].volume;
              return Intl.NumberFormat('en-US').format(Number(volume));
            }
            return '-';
          }
        }
      },
      {
        name: "market",
        label: "Market",
        options: {
          filter: false
        }
      },
      {
        name: "amount",
        label: "Amount",
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            if(orders[dataIndex] !== undefined){
              let amount = orders[dataIndex].amount;
              return Intl.NumberFormat('en-US').format(Number(amount));
            }
            return '-';
          }
        }
      },
      {
        name: "status",
        label: "Status",
      },
      {
        name: "type",
        label: "Type",
        options: {
          filter: false
        }
      },
      {
        name: "created_at",
        label: "Date",
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            if(orders[dataIndex] !== undefined){
              let createdAt = orders[dataIndex].created_at;
              return createdAt.fa;
            }
            return '-';
          }
        }
      },
      {
        name: 'options',
        label: 'Options',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            if(orders[dataIndex] !== undefined){
              return (
                <Button onClick={() => handleOpenOrderModal(dataIndex)} variant={'outlined'} size={'small'}>Manage</Button>
              );
            }
            return '-';
          }
        }
      }
    ];
      setColumns(cols);
  }, [orders])

  const handleOpenUserInfoModal = (dataIndex: number) => {

  }

  const handleOpenOrderModal = (dataIndex: number) => {
    setOrderModal(true);
    setOrderLoading(true);
    //fetch
    fetch(ORDERS_API + orders[dataIndex].id, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken.value}`
      },
    })
      .then(response => {
        if(response.ok){
          return response.json();
        }

        return Promise.reject(response);
      })
      .then(data => {
        setOrder(data.data);
      })
      .catch((error) => {
        if(error instanceof Response){
          error.json().then((data: any) => {
            let errMessage = '';
            if(data?.data?.errors && data.data.errors.length > 0){
              errMessage = data.data.errors[0]
            }else{
              errMessage = data.message;
            }

          })
        }else{

        }
      })
      .finally(() => {
        setOrderLoading(false);
      })
  }

  const handleCloseOrderModal = () => {
    setOrder(null);
    setOrderModal(false);
  }

  const handleMarkOrderAsDone = () => {
    if(!order) return;

    //change status
    setSubmitting(true);

    //send request
    fetch(ORDERS_API + order.id, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken.value}`
      },
    })
      .then(response => {
        if(response.ok){
          return response.json();
        }

        return Promise.reject(response);
      })
      .then(data => {
        setOrder({...order, status: 'DONE'})
        setOrders(orders.map((o, i) => {
          if(o.id === order.id){
            o = {...o, status: 'DONE'}
          }

          return o;
        }))

      })
      .catch((error) => {
        if(error instanceof Response){
          error.json().then((data: any) => {
            let errMessage = '';
            if(data?.data?.errors && data.data.errors.length > 0){
              errMessage = data.data.errors[0]
            }else{
              errMessage = data.message;
            }

          })
        }else{

        }
      })
      .finally(() => {
        setSubmitting(false);
      })
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {loading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <MUIDataTable
          title={"Orders List"}
          data={orders}
          columns={columns}
          options={options}
        />
        {loading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
      </Grid>
      <Dialog
        open={orderModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseOrderModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Order Info"}</DialogTitle>
        <DialogContent>
          {!!order && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    key='order_id'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      ID
                    </TableCell>
                    <TableCell align="right">{order.id}</TableCell>
                  </TableRow>
                  <TableRow
                    key='order_id'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>
                    <TableCell align="right">{order.status}</TableCell>
                  </TableRow>
                  <TableRow
                    key='order_id'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Volume
                    </TableCell>
                    <TableCell align="right">{order.volume}</TableCell>
                  </TableRow>
                  <TableRow
                    key='order_id'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Amount
                    </TableCell>
                    <TableCell align="right">{order.amount}</TableCell>
                  </TableRow>
                  <TableRow
                    key='order_id'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      VoucherCode
                    </TableCell>
                    <TableCell align="right">{order?.voucher?.voucher_code}</TableCell>
                  </TableRow>
                  <TableRow
                    key='activation_code'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      ActivationCode
                    </TableCell>
                    <TableCell align="right">{order?.voucher?.activation_code}</TableCell>
                  </TableRow>
                  <TableRow
                    key='batch_number'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      BatchNumber
                    </TableCell>
                    <TableCell align="right">{order?.voucher?.batch_number}</TableCell>
                  </TableRow>
                  <TableRow
                    key='batch_number'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      CardNumber
                    </TableCell>
                    <TableCell align="right">{order?.card?.card_number}</TableCell>
                  </TableRow>
                  <TableRow
                    key='iban'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      IBAN
                    </TableCell>
                    <TableCell align="right">{order?.card?.iban}</TableCell>
                  </TableRow>
                  <TableRow
                    key='owner'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      OWNER
                    </TableCell>
                    <TableCell align="right">{order?.card?.owner}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          {(order && order.status.toLowerCase() === 'pending' && !submitting) && (
            <Button variant={'contained'} color={'warning'} onClick={handleMarkOrderAsDone}>Mark as Done!</Button>
          )}
          {(order && order.status.toLowerCase() === 'pending' && submitting) && (
            <Button variant={'contained'} color={'secondary'} disabled>Please wait...</Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}