import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useState} from "react";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {AccessTokenActionType, useAccessTokenDispatch} from "../../contexts/AccessToken";
import {Alert} from "@mui/material";
import CopyRight from "../../components/CopyRight/CopyRight";
import {LOGIN} from "../../utils/API";

export default function LoginPage() {
  const accessTokenDispatch = useAccessTokenDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required field'),
      password: Yup.string().required('Required field')
    }),
    onSubmit: (values, {setStatus, setErrors}) => {
      fetch(LOGIN, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(values),
      }).then(response => {
        if(response.ok){
          return response.json();
        }

        return Promise.reject(response);
      }).then(data => {
        formik.setStatus(data.message);
        localStorage.setItem('access_token', data.data.token);

        // @ts-ignore
        accessTokenDispatch({
          type: AccessTokenActionType.SET_TOKEN,
          payload: data.data.token
        });
      }).catch((error) => {
        if(error instanceof Response){
          error.json().then((data: any) => {
            let errMessage = '';
            if(data?.data?.errors && data.data.errors.length > 0){
              errMessage = data.data.errors[0]
            }else{
              errMessage = data.message;
            }
            setErrors({
              email: errMessage
            });
          })
        }else{
          setErrors({
            email: error.message
          });
        }
      }).finally(() => {
        formik.setSubmitting(false);
      })
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {!!formik.status && (
          <Alert severity='success'>{formik.status}</Alert>
        )}
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          {!formik.isSubmitting && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!formik.isValid}
            >
              Sign In
            </Button>
          )}
          {formik.isSubmitting && (
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled
            >
              Please wait...
            </Button>
          )}
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CopyRight sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}