import React from "react";
import {createBrowserRouter} from "react-router-dom";
import LoginPage from "../pages/Login/Login";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import App from "../App";
import DashboardPage from "../pages/Dashboard/Dashboard";
import PanelLayout from "../components/PanelLayout";
import AuthLayout from "../components/AuthLayout";
import OrdersPage from "../pages/Orders/OrdersPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <PanelLayout />,
        children: [
          {
            path: "",
            element: <DashboardPage />,
          },
          {
            path: "orders",
            element: <OrdersPage />,
          }
        ]
      },
      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            path: "login",
            element: <LoginPage />,
          }
        ]
      }
    ]
  }
]);

export default router;